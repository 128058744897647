@use "variables" as *;

@mixin dropdown {
  [disabled] {
    color: $grayscale-50-color;
    cursor: not-allowed;
  }

  ul {
    li {
      &:not(:first-child) {
        padding-top: $spacing-1;
      }
    }

    a {
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
        background-image: none;
      }
      span {
        width: 100%;
      }
    }

    button {
      text-align: left;
    }
  }
}

@mixin dropdownItem {
  height: 34px;
  width: 100%;
  justify-content: left;

  svg {
    color: $icon-primary-color;
  }
}
