@use "variables" as *;

@mixin table-item-layout {
  @include on-bp(xl) {
    margin-left: $spacing-2;
    margin-right: $spacing-2;
    &:first-child {
      margin-top: $spacing-2;
    }
    &:last-child {
      margin-bottom: $spacing-2;
    }
  }
}

$table-item-min-height: 70px;

@mixin table-item-grid {
  display: grid;

  @include on-bp(xl) {
    grid-template-columns: 5% 9% 7% 14% 33% 11% 10% 11%;
    align-items: center;
    width: calc(100% - 36px);
  }

  > div {
    padding: 0 $spacing-2;
  }

  > div.pushLeft {
    justify-self: start;
  }

  > div.center {
    justify-self: center;
  }
}

@mixin table-item-grid-view-only {
  grid-template-columns: 9% 7% 14% 33% 11% 10% 11% !important;
}

$table-body-height: calc(100vh - (303px + #{$spacing-8}));
